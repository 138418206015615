import React from "react"
import ImageMeta from "../ImageMeta"

import { Transformation } from "cloudinary-react"
import { Link } from "gatsby"
import { getMonthYearFromStrings } from "../../utils/utils"

import ImageCaption from "./ImageCaption"

var classNames = require("classnames")

function SingleSlide(props) {
  let title = props.title
  if (title.charAt(0) !== "/") title = "/" + title

  let nameClasses = classNames("image-caption staff fixed-facewall-name", {
    last: !props.procedure && !props.monthYear,
  })
  let procedureClasses = classNames(
    "image-caption staff fixed-facewall-procedure",
    {
      last: !props.monthYear,
    }
  )
  let timeClasses = classNames("image-caption staff fixed-facewall-time", {
    last: true,
  })
  return (
    <div>
      <Link className="carousel-image-link" to={title.toLowerCase()}>
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.thumbnailPublicId}
          responsive
          responsiveUseBreakpoints="true"
          noLazyload
        >
          <Transformation
            quality="auto"
            fetchFormat="auto"
            crop="scale"
            height="419"
          />
        </ImageMeta>
        <ImageCaption
          reviewerName={props.reviewerName}
          language={props.language}
          procedure={props.otherProcedureName ? props.otherProcedureName : props.procedure}
          monthYear={props.monthYear}
        />
      </Link>
    </div>
  )
}

export default SingleSlide
